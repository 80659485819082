var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vodal',{attrs:{"show":_vm.isShowing,"animation":"zoom","closeButton":false},on:{"hide":() => {
      _vm.isShowing = false;
      this.$emit('hide');
    }}},[_c('b-card',{staticStyle:{"margin":"0px"},attrs:{"no-body":""}},[_c('b-card-header',[_c('i',{class:_vm.icon}),_vm._v("\n      "+_vm._s(_vm.modalTitle)+"\n      "),_c('div',{staticClass:"card-header-actions",staticStyle:{"margin-left":"10px"}},[(_vm.closeButton == true)?_c('b-button',{staticClass:"mr-1 btn-brand",attrs:{"size":"sm","variant":"danger"},on:{"click":() => {
              _vm.isShowing = false;
              this.$emit('hide');
            }}},[_c('i',{staticClass:"fa fa-remove"})]):_vm._e()],1)]),_vm._v(" "),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_vm._t("default")],2)],1)],1),_vm._v(" "),_c('b-card-footer',{staticClass:"text-right"},[(_vm.button1Title != null)?_c('b-button',{class:_vm.button1Class,attrs:{"size":"sm"},on:{"click":function($event){return _vm.click1()}}},[_c('i',{class:_vm.button1Icon}),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.button1Title)+" ")])]):_vm._e(),_vm._v(" "),(_vm.button2Title != null)?_c('b-button',{class:_vm.button2Class,staticStyle:{"margin-left":"10px"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.click2()}}},[_c('i',{class:_vm.button2Icon}),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.button2Title)+" ")])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }