<template>
  <multiselect
    :options="list"
    :multiple="isMultiple"
    :label="displayField"
    :track-by="valueField"
    @input="onSelect"
    v-model="selected"
    :placeholder="placeholder"
    :showLabels="false"
  >
    <template slot="noResult"> {{ $t("dataNotFound") }} </template>
  </multiselect>
</template>

<script>
import http from "../../shared/http";
import jwtHelper from "../../shared/jwt-helper";
import { getTranslation } from "../../shared/language-helper";
export default {
  name: "CategoryDropDown",
  props: {
    displayField: { type: String, required: true },
    isMultiple: { type: Boolean, default: false },
    valueField: { type: String, required: true },
    placeholder: {
      default: "",
      type: String,
      required: false,
    },
    apiSource: { type: String, required: true },
    selectedValue: { required: true },
    includeall: { required: false, default: false },
    firstSelected: { type: Boolean, default: false },
    filter: { type: Function, default: null },
    firstText: {
      type: String,
    },
    justUserCategories: { type: Boolean, default: false },
  },
  data() {
    return {
      list: [],
      selected: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("change", item);
      } else {
        this.$emit("change", null);
      }
    },
    getJustUserCategories(categories) {
      const userId = jwtHelper.getUserId();
      http
        .get(`UserCategoryRelations/getbyuserid?userId=${userId}`)
        .then((response) => {
          let filteredCategory = [];
          categories.foreach((item) => {
            if (response.filter((p) => p.categoryId == item.id).length > 0) {
              filteredCategory.push(item);
            }
          });
          return filteredCategory;
        });
    },
    getList() {
      let path = this.apiSource;
      http
        .get(path)
        .then((response) => {
          if (response) {
            var data = response;
            if (this.filter != null) {
              data = data.filter(this.filter);
            }
            if (this.includeall) {
              data.unshift({
                id: null,
              });
              data[0][this.displayField] = this.firstText;
              this.selected = this.list.find((i) => i.id == null);
            }
            this.list = data;
            if (this.justUserCategories) {
              const userId = jwtHelper.getUserId();
              http
                .get(`UserCategoryRelations/getbyuserid?userId=${userId}`)
                .then((response) => {
                  let filteredCategory = [];
                  data.forEach((item) => {
                    if (
                      response.filter((p) => p.categoryId == item.id).length > 0
                    ) {
                      filteredCategory.push(item);
                    }
                  });
                  this.list = filteredCategory;
                  this.$emit("binded", this.list);
                  if (this.firstSelected) {
                    this.selected = this.list[0];
                    this.$emit("change", this.selected);
                  }
                });
            } else {
              this.$emit("binded", this.list);
              if (this.firstSelected) {
                this.selected = this.list[0];
                this.$emit("change", this.selected);
              }
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.setSelected();
        });
    },
    setSelected() {
      if (this.list && this.list.length > 0) {
        if (this.selectedValue && this.selectedValue.length > 0) {
          for (let index = 0; index < this.selectedValue.length; index++) {
            const element = this.selectedValue[index];
            var item = this.list.filter((t) => t.id === element.categoryId);
            if (item && item.length > 0) {
              this.selected.push(item[0]);
            }
          }
        } else {
          this.selected = [];
        }
        this.$emit("change", this.selected);
      }
    },
  },
  watch: {
    selectedValue() {
      this.setSelected();
    },
  },
};
</script>