import stringHelper from './string-helper';
import dateFormat from 'dateformat'
function getDateInFormat(strFormat, strDate = null) {
    let date = new Date();
    if (strDate != null) {
        date = new Date(strDate);
    }
    return dateFormat(date, strFormat);
}
function addDay(str, count) {
    var date;
    if (str != null) {
        date = new Date(str);
    }
    else {
        date = new Date();
    }
    let newDate = new Date(date.setDate(date.getDate() + count));
    let day = stringHelper.padRight(newDate.getDate(), '00');
    let month = stringHelper.padRight(newDate.getMonth() + 1, '00');
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
}

function addMonth(str, count) {
    var date = new Date(str);
    let newDate = new Date(date.setMonth(date.getMonth() + count));
    let day = stringHelper.padRight(newDate.getDate(), '00');
    let month = stringHelper.padRight(newDate.getMonth() + 1, '00');
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
}

function addYear(str, count) {
    var date = new Date(str);
    let newDate = new Date(date.setFullYear(date.getFullYear() + count));
    let day = stringHelper.padRight(newDate.getDate(), '00');
    let month = stringHelper.padRight(newDate.getMonth() + 1, '00');
    let year = newDate.getFullYear();
    return year + "-" + month + "-" + day;
}

function toInputDateFormat(str) {
    var date = new Date(str);
    let day = stringHelper.padRight(date.getDate(), '00');
    let month = stringHelper.padRight(date.getMonth() + 1, '00');
    let year = date.getFullYear();
    return year + "-" + month + "-" + day;
}
function getDate(strDate = "") {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (strDate != "") {
        d = new Date(strDate),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    }
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('/');
}
function getTime(strDate = "") {
    var d = new Date(),
        hour = '' + (d.getHours()),
        minute = '' + d.getMinutes(),
        second = d.getSeconds();
    if (strDate != "") {
        d = new Date(strDate),
            hour = '' + (d.getHours()),
            minute = '' + d.getMinutes(),
            second = d.getSeconds();
    }
    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    if (second.length < 2)
        second = '0' + second;

    return [hour, minute, second].join(':');
}
function getFromDateFormat(date) {
    return date ? date + " 00:00:01" : null
}
function getToDateFormat(date) {
    return date ? date + " 23:59:59" : null
}
function getPublishDateTime(publishDate, publishTime) {
    publishDate = publishDate.substr(0, publishDate.indexOf(' '));
    let parts = publishDate.split('/');
    return parts[2] + '/' + parts[0] + '/' + parts[1] + ' ' + publishTime;
}
function isItPast(date) {
    let checkDate = new Date(date);
    let today = new Date();
    if (checkDate < today) {
        return true;
    }
    else {
        return false;
    }
}
export default { addDay, addMonth, addYear, toInputDateFormat, getToDateFormat, getFromDateFormat, getDate, getTime, getPublishDateTime, isItPast, getDateInFormat };