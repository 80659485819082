<template>
  <multiselect
    :options="list"
    :multiple="false"
    label="entityName"
    track-by="id"
    @input="onSelect"
    v-model="selected"
    :placeholder="placeholder"
    :showLabels="false"
  >
    <template slot="noResult"> {{ $t("dataNotFound") }} </template></multiselect
  >
</template>

<script>
import http from "../shared/http";
import { getTranslation } from "../shared/language-helper";
export default {
  name: "EntitiesDropdown",
  props: {
    placeholder: {
      default: "",
      type: String,
      required: false,
    },
    endPoint: { required: true },
    entityId: { required: true },
    includeall: { required: false, default: false },
  },
  data() {
    return {
      list: [],
      selected: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("change", item);
      } else {
        this.$emit("change", null);
      }
    },
    getList() {
      let path = `Entities/${this.endPoint}`;
      http.get(path).then((response) => {
        if (response) {
          var data = response;
          if (this.includeall) {
            data.unshift({
              id: null,
              entityName: this.$t("all"),
            });
          }

          this.list = data;
          this.selected = this.list.find((i) => i.id == null);
          this.setSelected();
        }
      });
    },
    setSelected() {
      this.selected = this.list.find((i) => i.id == this.entityId) || null;
    },
  },
  watch: {
    entityId() {
      this.setSelected();
    },
  },
};
</script>