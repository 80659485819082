
import jwtDecode from 'jwt-decode';

function getParsedJwt() {
    var token = localStorage.getItem("token");
    if (!token) return null;
    return jwtDecode(token);
}

function getRoles() {
    var parsedJwt = getParsedJwt();
    if (!parsedJwt) return null;
    return parsedJwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
}

function getEmail() {
    var parsedJwt = getParsedJwt();
    if (!parsedJwt) return null;
    return parsedJwt["email"];
}

function getNameSurname() {
    var parsedJwt = getParsedJwt();
    if (!parsedJwt) return null;
    return parsedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
}

function getUserId() {
    var parsedJwt = getParsedJwt();
    if (!parsedJwt) return null;
    return Number(parsedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]);
}

function getIsEmployee() {
    var parsedJwt = getParsedJwt();
    if (!parsedJwt) return null;
    return Number(parsedJwt["isemployee"]);
}

function getUserName() {
    var parsedJwt = getParsedJwt();
    if (!parsedJwt) return null;
    return parsedJwt["unique_name"];
}



export default { getParsedJwt, getRoles, getEmail, getNameSurname, getUserId, getIsEmployee, getUserName, };