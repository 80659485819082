<template>
  <b-button
    size="sm"
    :class="buttonClass"
    :variant="buttonVariant"
    @click="click()"
  >
    <i :class="buttonIcon"></i>
    <span>{{ text }}</span>
  </b-button>
</template>
<script>
export default {
  name: "BrandButton",
  props: {
    buttonClass: { type: String, default: "mr-1 btn-brand" },
    buttonIcon: { type: String, default: "fa fa-plus" },
    buttonVariant: { type: String, default: "primary" },
    text: { type: String, required: true },
  },
  data() {
    return {};
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>