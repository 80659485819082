import { getTranslation } from './language-helper';

function getTexts() {
    return {
        count: getTranslation('gridCount'),
        first: getTranslation('first'),
        last: getTranslation('last'),
        filter: getTranslation('filter'),
        filterPlaceholder: getTranslation('search'),
        limit: getTranslation('showCount'),
        page: getTranslation('page'),
        noResults: getTranslation('itemNotFound'),
        filterBy: getTranslation('filteredColumn'),
        loading: getTranslation('loading'),
        defaultOption: getTranslation('select'),
        columns: getTranslation('columns')
    }
}

function getPagination() {
    return {
        chunk: 4,
        nav: "fixed",
        edge: false,
        dropdown: false,
    };
}

function getSortIcon() {
    return {
        base: "fa",
        up: "fa-sort-asc",
        down: "fa-sort-desc",
        is: "fa-sort"
    };
}

function getAll() {
    return {
        sortIcon: getSortIcon(),
        pagination: getPagination(),
        texts: getTexts(),
        perPage: 10,
        debounce: 1200,
        filterable: true,
    }
}

export default { getTexts, getPagination, getSortIcon, getAll };