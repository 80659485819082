<template>
  <b-row>
    <b-col sm="6">
      <b-form-group :label="$t('fromDate')" label-for="filterFromDate">
        <b-input-group>
          <b-form-datepicker
            :placeholder="$t('chooseDate')"
            id="filterFromDate"
            v-model="filterFromDate"
            :locale="local"
            v-bind="labels"
            @context="onSelect"
          ></b-form-datepicker>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col sm="6">
      <b-form-group :label="$t('toDate')" label-for="filterToDate">
        <b-input-group>
          <b-form-datepicker
            :placeholder="$t('chooseDate')"
            id="filterToDate"
            v-model="filterToDate"
            @context="onSelect"
            :locale="local"
            v-bind="labels"
          ></b-form-datepicker>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import dateHelper from "../../shared/date-helper";
import { labels } from "../../lang/translations/date-time-picker/labels";

export default {
  name: "DateBetweenPicker",
  props: {
    from: {
      default: null,
      type: String,
      required: false,
    },
    to: {
      default: null,
      type: String,
      required: false,
    },
  },
  created() {
    if (this.from) {
      this.filterFromDate =
        this.from ??
        dateHelper.toInputDateFormat(dateHelper.addYear(new Date(), -1));
      this.filterToDate =
        this.to ?? dateHelper.toInputDateFormat(new Date().toDateString());
    }
    this.local = localStorage.lang;
  },
  methods: {
    onSelect() {
      if (this.filterFromDate && this.filterToDate) {
        this.$emit("change", {
          from: this.filterFromDate,
          to: this.filterToDate,
        });
      } else {
        this.$emit("change", null);
      }
    },
  },
  data() {
    return {
      local: "tr",
      labels: labels,
      filterFromDate: null,
      filterToDate: null,
    };
  },
};
</script>
