<template>
  <vodal
    :show="isShowing"
    animation="zoom"
    @hide="
      () => {
        isShowing = false;
        this.$emit('hide');
      }
    "
    :closeButton="false"
  >
    <b-card no-body style="margin: 0px">
      <b-card-header>
        <i :class="icon"></i>
        {{ modalTitle }}
        <div class="card-header-actions" style="margin-left: 10px">
          <b-button
            size="sm"
            class="mr-1 btn-brand"
            variant="danger"
            v-if="closeButton == true"
            @click="
              () => {
                isShowing = false;
                this.$emit('hide');
              }
            "
          >
            <i class="fa fa-remove"></i>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <slot></slot>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer class="text-right">
        <b-button
          v-if="button1Title != null"
          size="sm"
          :class="button1Class"
          @click="click1()"
        >
          <i :class="button1Icon"> </i>
          <span> {{ button1Title }} </span>
        </b-button>
        <b-button
          v-if="button2Title != null"
          size="sm"
          style="margin-left: 10px"
          :class="button2Class"
          @click="click2()"
        >
          <i :class="button2Icon"> </i>
          <span> {{ button2Title }} </span>
        </b-button>
      </b-card-footer>
    </b-card>
  </vodal>
</template>
<script>
export default {
  name: "ModalComponent",
  props: {
    modalTitle: String,
    show: { type: Boolean, default: false },
    button1Title: { type: String, default: null },
    button2Title: { type: String, default: null },
    button1Class: { type: String, default: "btn-success" },
    button2Class: { type: String, default: "btn-danger" },
    button1Icon: { type: String, default: "" },
    button2Icon: { type: String, default: "" },
    icon: { type: String, default: "icon-bell mr-1" },
    closeButton: { type: Boolean, default: true },
  },
  data() {
    return {
      isShowing: this.show,
    };
  },
  watch: {
    show() {
      this.isShowing = this.show;
    },
  },
  methods: {
    click1() {
      this.$emit("onButton1Click");
    },
    click2() {
      this.$emit("onButton2Click");
    },
  },
};
</script>
<style>
.vodal-dialog {
  padding: 2px;
  width: fit-content !important;
  background-color:transparent;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .0);
box-shadow: 0 1px 3px rgba(0, 0, 0, .0);
}
</style>