export const labels = {
    labelPrevDecade: 'Önceki on yıl',
    labelPrevYear: 'Geçen yıl',
    labelPrevMonth: 'Geçen ay',
    labelCurrentMonth: 'İçinde bulunduğumuz ay',
    labelNextMonth: 'Gelecek ay',
    labelNextYear: 'Gelecek yıl',
    labelNextDecade: 'Önümüzdeki on yıl',
    labelToday: 'Bugün',
    labelSelected: 'Seçilen tarih',
    labelNoDateSelected: 'Tarih seçilmedi',
    labelCalendar: 'Takvim',
    labelNav: 'Takvimde gezinme',
    labelHelp: 'Ok tuşlarıyla takvimde gezinin'
}