function padRight(str, pad) {
    str = '' + str;
    return pad.substring(0, pad.length - str.length) + str;
}

function standardUrl(str) {
    str = str.toLowerCase();
    str = str.split(" ").join("-");
    str = str.split("'").join("-");
    str = str.split("‘’").join("");
    str = str.split("’").join("-");
    str = str.split('"').join("");
    str = str.split('!').join("-");
    str = str.split('?').join("-");
    str = str.split('.').join("-");
    str = str.split(',').join("-");
    str = str.split('&').join("-");
    str = str.split('ı').join("i");
    str = str.split('ğ').join("g");
    str = str.split('ü').join("u");
    str = str.split('ş').join("s");
    str = str.split('ö').join("o");
    str = str.split('ç').join("c");
    str = str.split('--').join("-");
    str = str.replace(/[^a-z0-9-]/g, '');
    return str;
}

function standardUrlWithSlash(str) {
    // str = str.toLowerCase();
    // str = str.split(" ").join("-");
    // str = str.split("'").join("-");
    // str = str.split('"').join("");
    // str = str.split('!').join("-");
    // str = str.split('?').join("-");
    // str = str.split('.').join("-");
    // str = str.split(',').join("-");
    // str = str.split('&').join("-");
    // str = str.split('ı').join("i");
    // str = str.split('ğ').join("g");
    // str = str.split('ü').join("u");
    // str = str.split('ş').join("s");
    // str = str.split('ö').join("o");
    // str = str.split('ç').join("c");
    // str = str.split('--').join("-");
    // str = str.replace(/[^a-z0-9/-]/g, '');
    return str;

}

function isValidNumChar(e) {
    if (e.toLowerCase() === 'backspace') return true;
    return e === standardUrl(e);
}

function isValidNumCharWithSlash(keycode) {
    // if ((keycode >= 97 && keycode <= 122) ||
    //     (keycode >= 65 && keycode <= 90) ||
    //     (keycode >= 48 && keycode <= 57) || keycode == 47 ||
    //     keycode == 191 ||
    //     keycode == 111 ||
    //     keycode == 55 ||
    //     keycode == 211 ||
    //     keycode == 192 ||
    //     keycode == 32 || keycode == 8 || keycode == 173 || keycode == 9 || keycode == 189) {
    //     return true;
    // }
    // return false;
    return true;
}

function standardSize(string) {
    string = string.replace("(", "");
    string = string.replace(")", "");
    return string;
}
export default { padRight, standardUrl, standardSize, isValidNumChar, isValidNumCharWithSlash, standardUrlWithSlash };