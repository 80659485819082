<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      button1Class="btn btn-danger"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>
    <CustomModal
      :modalTitle="$t('shareNews')"
      :button2Title="$t('close')"
      :onButton2Click="() => (shareModal = false)"
      :show="shareModal"
      @hide="shareModal = false"
    >
      <b-row>
        <b-col sm="6" class="text-center p-2">
          <b-link :href="getShareLink('twitter')" target="blank">
            <b-img src="img/twitter.png" rounded fluid height="50" width="50" />
          </b-link>
        </b-col>
        <b-col sm="6" class="text-center p-2">
          <b-link :href="getShareLink('facebook')" target="blank">
            <b-img
              src="img/facebook.png"
              rounded
              fluid
              height="50"
              width="50"
            />
          </b-link>
        </b-col>
        <b-col sm="6" class="text-center p-2">
          <b-link :href="getShareLink('whatsapp')" target="blank">
            <b-img
              src="img/whatsapp.png"
              rounded
              fluid
              height="50"
              width="50"
            />
          </b-link>
        </b-col>
        <b-col sm="6" class="text-center p-2">
          <b-link :href="getShareLink('telegram')" target="blank">
            <b-img
              src="img/telegram.png"
              rounded
              fluid
              height="50"
              width="50"
            />
          </b-link>
        </b-col>
      </b-row>
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("news") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('addNews')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="categories">{{ $t("categories") }}</label>
              <CategoryDropDown
                displayField="categoryName"
                valueField="id"
                ref="categoryDropDown"
                apiSource="categories/getlist"
                :isMultiple="true"
                :selectedValue="selectedCategories"
                :justUserCategories="true"
                @change="(item) => changed(item)"
              />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="newsAgencyEntityId">{{
                $t("newsAgencyEntity")
              }}</label>
              <EntitiesDropdown
                :placeholder="$t('newsAgencyEntity')"
                endPoint="getnewsagencyentities"
                :entityId="filters.newsAgencyEntityId"
                :includeall="true"
                @change="(val) => (filters.newsAgencyEntityId = val.id)"
              ></EntitiesDropdown>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="newsTypeEntityId">{{ $t("newsTypeEntity") }}</label>
              <EntitiesDropdown
                :placeholder="$t('newsTypeEntity')"
                endPoint="getnewstypeentities"
                :entityId="filters.newsTypeEntityId"
                :includeall="true"
                @change="(val) => (filters.newsTypeEntityId = val.id)"
              ></EntitiesDropdown>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="active">{{ $t("active") }}</label>
              <BooleanStateDropDown
                :placeholder="$t('active')"
                :state="filters.active"
                :includeall="true"
                @change="(val) => (filters.active = val.id)"
              ></BooleanStateDropDown>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="isDraft">{{ $t("draftFilter") }}</label>
              <BooleanStateDropDown
                :placeholder="$t('isDraft')"
                :state="filters.isDraft"
                :includeall="true"
                @change="(val) => (filters.isDraft = val.id)"
              ></BooleanStateDropDown>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <b-form-group>
                <label for="user">{{ $t("editors") }}</label>
                <users-dropdown
                  id="userId"
                  :userid="filters.userId"
                  @change="userChanged"
                  :isEmployee="true"
                ></users-dropdown>
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <DateBetweenPicker
              :from="filters.fromPublisedhAt"
              :to="filters.toPublishedAt"
              @change="dateChanged"
            ></DateBetweenPicker>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <i class="fa fa-filter mr-1"></i>
        {{ $t("filter") }}
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="12">
            <v-server-table
              :columns="columns"
              v-if="$auth.hasAuth($options, 'list')"
              ref="table"
              :options="options"
              id="dataTable"
              class="dataGrid datagridDetail gridDetail news-table"
            >
              <template slot-scope="props" slot="title">
                <div class="text-left">
                  <b-link
                    :href="
                      getPreviewLink(
                        props.row.id,
                        props.row.isDraft,
                        props.row.url,
                        props.row.newsTypeEntityId,
                        props.row.externalLink
                      )
                    "
                    target="blank"
                  >
                    {{ props.row.title }}
                  </b-link>
                </div>
              </template>
              <template slot="thumbnail" slot-scope="props">
                <b-img
                  :src="
                    props.row.thumbnail == null
                      ? '/img/noPic.png'
                      : props.row.thumbnail
                  "
                  fluid
                  height="40"
                  width="40"
                  thumbnail
                  :id="getPopoverId(props.row.id)"
                />
                <b-popover
                  :target="getPopoverId(props.row.id)"
                  triggers="hover"
                  placement="right"
                >
                  <template #title>{{ $t("image") }}</template>
                  <b-img
                    :src="props.row.thumbnail"
                    thumbnail
                    fluid
                    height="400"
                    width="400"
                  />
                </b-popover>
              </template>
              <template slot="active" slot-scope="props">
                <b-badge
                  size="lg"
                  :variant="props.row.active ? 'success' : 'danger'"
                  >{{ props.row.active ? $t("yes") : $t("no") }}</b-badge
                >
              </template>
              <template slot="publishDateTime" slot-scope="props">
                {{ getDate(props.row.publishDate) }}
                {{ getTime(props.row.publishTime) }}
              </template>
              <template slot="newsHitCount" slot-scope="props">
                <div class="text-center">
                  <b-button
                    @click="openHitList(props.row.id)"
                    size="sm"
                    variant="primary"
                    :id="'popover-button' + props.row.id"
                    >{{ props.row.newsHitCount }}</b-button
                  >
                  <b-popover
                    :ref="'popover' + props.row.id"
                    :target="'popover-button' + props.row.id"
                    title="Popover"
                    placement="left"
                    triggers="focus"
                  >
                    <template #title>{{ $t("hitList") }}</template>
                    <div class="text-center">
                      <p v-html="hitListContent"></p>
                    </div>
                  </b-popover>
                </div>
              </template>
              <template slot="isDraft" slot-scope="props">
                <b-badge
                  size="lg"
                  :variant="props.row.isDraft ? 'danger' : 'success'"
                  >{{ props.row.isDraft ? $t("no") : $t("yes") }}</b-badge
                >
              </template>
              <template slot="buttons" slot-scope="props">
                <div class="action-buttons">
                  <b-dropdown class="mx-1" right :text="$t('action')">
                    <b-dropdown-item
                      @click="edit(props.row.id)"
                      v-if="
                        $auth.hasAuth($options, 'edit')
                      "
                    >
                      <i class="text-primary fa fa-edit"></i>
                      {{ $t("edit") }}
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      v-if="
                        props.row.lockUserId &&
                        props.row.lockUserId !== currentUserId
                      "
                    >
                      <i class="text-danger fa fa-lock"></i>
                      {{ $t("newsLocked") }} ({{ props.row.lockUser }})
                    </b-dropdown-item> -->
                    <b-dropdown-item
                      @click="showRemoveModal(props.row.id)"
                      v-if="
                        $auth.hasAuth($options, 'remove')
                      "
                    >
                      <i class="text-danger fa fa-trash"></i>
                      {{ $t("delete") }}
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      @click="showComments(props.row.id)"
                      v-if="$auth.hasAuth($options, 'showComment')"
                    >
                      <i class="text-warning fa fa-comments"></i>
                      {{ $t("comments") }}
                    </b-dropdown-item> -->
                    <b-dropdown-item
                      v-if="
                        props.row.isDraft && $auth.hasAuth($options, 'publish')
                      "
                      @click="publishNews(props.row.id)"
                    >
                      <i class="text-dark fa fa-cloud-upload"></i>
                      {{ $t("publishNews") }}
                    </b-dropdown-item>
                    <!-- <b-dropdown-item
                      v-if="
                        !props.row.active && $auth.hasAuth($options, 'active')
                      "
                      @click="setNewsActive(props.row.id)"
                    >
                      <i class="text-success fa fa-check-square"></i>
                      {{ $t("setActive") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        props.row.active && $auth.hasAuth($options, 'deactive')
                      "
                      @click="setNewsDeactive(props.row.id)"
                    >
                      <i class="text-danger fa fa-minus-square"></i>
                      {{ $t("setDeactive") }}
                    </b-dropdown-item> -->
                    <b-dropdown-item
                      @click="showShareModal(props.row)"
                      v-if="props.row.active && !props.row.isDraft"
                    >
                      <i class="text-success fa fa-share"></i>
                      {{ $t("shareNews") }}
                    </b-dropdown-item>

                    <!-- <b-dropdown-item
                      v-if="!props.row.lockUserId"
                      @click="lockNews(props.row.id, true)"
                    >
                      <i class="text-success fa fa-lock"></i>
                      {{ $t("lockNews") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        props.row.lockUserId &&
                        props.row.lockUserId === currentUserId
                      "
                      @click="lockNews(props.row.id, false)"
                    >
                      <i class="text-success fa fa-unlock"></i>
                      {{ $t("unlockNews") }}
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </template>

              <div
                slot="child_row"
                class="text-left p-3"
                v-html="props.row.htmlContent"
                slot-scope="props"
              ></div>
            </v-server-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import UsersDropdown from "../../widgets/UsersDropdown";
import gridOptions from "../../shared/grid-options";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import dateHelper from "../../shared/date-helper";
import toast from "../../shared/toast-helper";
import CustomModal from "../../widgets/form/CustomModal";
import BrandButton from "../../widgets/form/CustomBrandButton";
import EntitiesDropdown from "../../widgets/EntitiesDropdown";
import BooleanStateDropDown from "../../widgets/BooleanStateDropDown";
import DateBetweenPicker from "../../widgets/form/DateBetweenPicker";
import toastHelper from "../../shared/toast-helper";
import CategoryDropDown from "../../widgets/news/CategoryDropDown";
import jwtHelper from "../../shared/jwt-helper";
export default {
  name: "NewsList",
  components: {
    UsersDropdown,
    ServerTable,
    BrandButton,
    EntitiesDropdown,
    CustomModal,
    BooleanStateDropDown,
    DateBetweenPicker,
    CategoryDropDown,
  },
  methods: {
    // lockNews(newsId, lockNews) {
    //   http
    //     .post(`News/locknews`, { newsId: newsId, lockNews: lockNews }, false)
    //     .then((response) => {
    //       toastHelper.success(response.message);
    //       this.getList();
    //     })
    //     .catch(() => {
    //       this.getList();
    //     });
    // },
    openHitList(newsId) {
      this.hitListContent = "<i class='fa fa-spinner fa-spin'></i>";
      this.$refs["popover" + newsId].$emit("open");
      http
        .get(
          `NewsHitDetails/getlisthitgroupbynewsId?newsId=${newsId}`,
          null,
          false
        )
        .then((response) => {
          if (response.length > 0) {
            this.hitListContent = "";
            response.forEach((element) => {
              this.hitListContent +=
                this.$t(element.newsHitEntityName) +
                ":" +
                element.count +
                "<br/>";
            });
          } else {
            this.hitListContent = this.$t("emptyHitList");
          }
        });
    },
    changed(val) {
      if (val) {
        var list = val.map((f) => f.id);
        this.filters.selectedCategoryIds = list.join();
      } else this.filters.selectedCategoryIds = null;
    },
    userChanged(user) {
      this.filters.userId = user?.id ?? null;
    },
    getShareLink(socialName) {
      if (this.shareNewsObject.url !== undefined) {
        const Url = process.env.VUE_APP_UI_URL + this.shareNewsObject.url;
        switch (socialName) {
          case "facebook":
            return `https://www.facebook.com/sharer/sharer.php?u=${Url}&t=${this.shareNewsObject.title}`;
            break;
          case "whatsapp":
            return `https://api.whatsapp.com/send?text=${Url}`;
            break;
          case "twitter":
            return `https://twitter.com/intent/tweet?url=${Url}&text=${this.shareNewsObject.title}`;
            break;
          case "telegram":
            return `https://telegram.me/share/url?url=${Url}&text=${this.shareNewsObject.title}`;
            break;
        }
      }
    },
    showShareModal(newsObject) {
      this.shareNewsObject = newsObject;
      this.shareModal = true;
    },
    getPreviewLink(newsId, isDraft, url, newsTypeEntityId, externalLink) {
      //if (!isDraft) {
      // if (url != null && url.length > 0) {
      if (newsTypeEntityId === 61) return externalLink;

      return `${process.env.VUE_APP_UI_URL}${url}`;
      //  }
      // }
      //return `${process.env.VUE_APP_UI_URL}/043fe94c-710d-4287-a337-b7c2a0d2857e/${newsId}`;
    },
    getPopoverId(id) {
      return "popover" + id;
    },
    getList() {
      this.$refs.table.setPage(1);
    },
    getTime(val) {
      if (val.indexOf(".") >= 0) {
        return val.substr(0, val.indexOf(".")).substr(0, 5);
      } else {
        return val.substr(0, 5);
      }
    },
    getDate(val) {
      return dateHelper.getDateInFormat("dd/mm/yyyy", val);
    },
    add() {
      this.$router.push("/news-add");
    },
    remove() {
      http.post(`News/delete?id=${this.selectedId}`).then((response) => {
        if (response.success) {
          this.getList();
          this.removeModal = false;
          this.selectedId = 0;
          toast.success(response.message);
        }
      });
    },
    showComments(newsId) {
      this.$router.push("/news-comments/" + newsId);
    },
    setNewsActive(newsId) {
      this.setActiveState(newsId, true);
    },
    setNewsDeactive(newsId) {
      this.setActiveState(newsId, false);
    },
    setActiveState(newsId, state) {
      const model = {
        id: newsId,
        active: state,
      };
      http.post(`News/changeactivestatus`, model).then((response) => {
        toastHelper.success(response.message);
        this.getList();
      });
    },
    publishNews(newsId) {
      const model = {
        id: newsId,
        isDraft: false,
      };
      http.post(`News/changeisdraftstatus`, model).then((response) => {
        if (response) {
          toastHelper.success(response.message);
          this.getList();
        }
      });
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    edit(id) {
      this.$router.push({ path: `news-add/${id}` });
    },
    dateChanged(dates) {
      this.filters.fromPublishedAt = dates?.from ?? null;
      this.filters.toPublishedAt = dates?.to ?? null;
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  data() {
    return {
      currentUserId: jwtHelper.getUserId(),
      removeModal: false,
      shareModal: false,
      shareNewsObject: {},
      selectedCategories: null,
      hitListContent: "",
      filters: {
        active: null,
        isDraft: null,
        newsAgencyEntityId: null,
        newsTypeEntityId: null,
        approved: null,
        fromPublishedAt: null,
        toPublishedAt: null,
        userId: null,
        selectedCategoryIds: null,
      },
      columns: [
        "thumbnail",
        "title",
        "addUser",
        "active",
        "isDraft",
        "newsTypeEntity",
        "newsAgencyEntity",
        "publishDateTime",
        "newsHitCount",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        pagination: { chunk: 10 },

        perPageValues: [10, 25, 50, 100],
        columnsClasses: {
          thumbnail: "text-center",
          addUser: "text-center",
          active: "text-center",
          isDraft: "text-center",
          newsTypeEntity: "text-center",
          newsAgencyEntity: "text-center",
          publishDateTime: "text-center",
          newsHitCount: "text-center",
        },
        requestFunction: (data) => {
          let path = "News/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "publishDate";
            data.ascending = 0;
          }
          if (data.orderBy === "publishDateTime") {
            data.orderBy = "publishDate";
          }
          if (this.filters) {
            data.newsAgencyEntityId = this.filters.newsAgencyEntityId;
            data.newsTypeEntityId = this.filters.newsTypeEntityId;
            data.active = this.filters.active;
            data.isDraft = this.filters.isDraft;
            data.fromPublishedAt = this.filters.fromPublishedAt;
            data.toPublishedAt = this.filters.toPublishedAt;
            data.userId = this.filters.userId;
            data.selectedCategoryIds = this.filters.selectedCategoryIds;
          }

          return http.get(path, { params: data }).catch(function (e) {
            this.dispatch("error", e);
          });
        },
        sortable: "title,shortDescription,addUser,isDraft,publishDateTime",
        headings: {
          title: this.$t("title"),
          addUser: this.$t("addUser"),
          isDraft: this.$t("draftList"),
          publishDateTime: this.$t("publishDate"),
          thumbnail: this.$t("thumbnail"),
          newsTypeEntity: this.$t("newsTypeEntity"),
          newsAgencyEntity: this.$t("newsAgencyEntity"),
          newsHitCount: this.$t("newsHitCount"),
          active: this.$t("active"),
          buttons: this.$t("operations"),
        },
      },
    };
  },
};
</script>
<style scoped>
td {
  padding: 0px !important;
}
.badge {
  min-width: 30px;
  min-height: 20px;
  padding-top: 5px !important;
}
</style>