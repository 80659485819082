<template>
  <multiselect
    :options="list"
    :multiple="false"
    label="value"
    track-by="id"
    @input="onSelect"
    v-model="selected"
    :placeholder="placeholder"
    :showLabels="false"
  >
    <template slot="noResult"> {{ $t("dataNotFound") }} </template></multiselect
  >
</template>

<script>
import { getTranslation } from "../shared/language-helper";
import { getBooleanStates } from "../shared/utils/generators-helper";
export default {
  name: "BooleanStateDropDown",
  props: {
    placeholder: {
      default: getTranslation("state"),
      type: String,
      required: false,
    },
    state: { required: true },
    includeall: { required: false, default: true },
  },
  data() {
    return {
      list: [],
      selected: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onSelect(item) {
      if (item) {
        this.$emit("change", item);
      } else {
        this.$emit("change", null);
      }
    },
    getList() {
      var data = getBooleanStates();
      if (this.includeall) {
        data.unshift({
          id: null,
          value: this.$t("all"),
        });
      }

      this.list = data;
      this.selected = this.list.find((i) => i.id == null);
      this.setSelected();
    },
    setSelected() {
      this.selected = this.list.find((i) => i.id == this.state) || null;
    },
  },
  watch: {
    state() {
      this.setSelected();
    },
  },
};
</script>